import styled from 'styled-components';

export const PageStyles = styled.div`
  // Explode layer
  #load-editor-button span:not(:last-of-type) {
    position: absolute;
    inset: -2px;
    border-radius: inherit;
    border: inherit;
    opacity: 0;
  }

  #load-editor-button span:last-of-type {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  #load-editor-button span:nth-of-type(3) {
    background: linear-gradient(#fff 0 0) padding-box;
  }
  #load-editor-button span:nth-of-type(2) {
    background: linear-gradient(0deg, #0000 0, var(--border) 18px) border-box;
  }
  #load-editor-button span:nth-of-type(1) {
    background: linear-gradient(#0000 0 0), linear-gradient(#0000 0 0),
      linear-gradient(in lch longer hue 90deg, var(--base) 0 0) border-box 0 0 /
        400% 100%;
  }

  .wrap #load-editor-button::before {
    transform: translate3d(0, 0, -1px);
  }

  [data-explode='false'] {
    .wrap {
      transition: transform 1s 1s;
    }

    .wrap #load-editor-button {
      transition: outline-color 1s;
    }

    .wrap #load-editor-button span,
    .wrap #load-editor-button::before {
      transition: transform 1s, opacity 0s 1s, outline-color 1s, scale 0.25s;
    }
  }

  .wrap #load-editor-button span:nth-of-type(3),
  .wrap #load-editor-button {
    outline: 4px dashed #0000;
  }

  [data-explode='true'] {
    .wrap {
      transform: scale(var(--scale, 1)) rotateX(-32deg) rotateY(45deg)
        rotateX(90deg);
      transition: transform 1s;
    }
    .wrap #load-editor-button::before {
      --i: -1;
    }

    .wrap #load-editor-button {
      background: none;
      outline: 4px dashed #000;
      transition: outline-color 1s 1s;
    }

    .wrap #load-editor-button span,
    .wrap #load-editor-button::before {
      opacity: 1;
      transform: translate3d(0, 0, calc(var(--i) * 50px));
      transition: scale 0.25s 0.1s, transform 1s 1s, outline-color 1s 1s;
    }

    .wrap #load-editor-button span:nth-of-type(4) { --i: 4; }
    .wrap #load-editor-button span:nth-of-type(1) { --i: 1; }
    .wrap #load-editor-button span:nth-of-type(2) { --i: 2; }
    .wrap #load-editor-button span:nth-of-type(3) {
      --i: 3;
      outline: 4px dashed rgba(0, 0, 0, 0.3);
    }
  }

  // Rainbow layer
  .wrap > #load-editor-button,
  .wrap > #load-editor-button span:nth-of-type(1),
  .wrap > #load-editor-button::before,
  .wrap > #load-editor-button::after {
    animation-composition: add;
    animation: rainbow 12s infinite linear,
      rainbow 6s infinite linear var(--state, paused);
  }

  .wrap > #load-editor-button {
    background: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(0deg, #0000 0, var(--border) 18px) border-box,
      linear-gradient(in lch longer hue 90deg, var(--base) 0 0) border-box 0 0 /
        400% 100%;
  }

  .wrap > #load-editor-button::before {
    background: linear-gradient(#0000 0 0), linear-gradient(#0000 0 0),
      linear-gradient(in lch longer hue 90deg, var(--base) 0 0) border-box 0 0 /
        400% 100%;
  }

  .wrap > #load-editor-button::after {
    background: linear-gradient(#0000 0 0) padding-box,
      linear-gradient(#0000 0 0) border-box,
      linear-gradient(in lch longer hue 90deg, var(--base) 0 0) border-box 0 0 /
        400% 100%;
    mask: linear-gradient(#0000 0 0), linear-gradient(#fff 0 0);
    mask-clip: padding-box, border-box;
    mask-composite: intersect;
  }

  #load-editor-button:is(:hover) {
    --state: running;
  }

  @keyframes rainbow {
    to {
      background-position: 0 0, 0 0, 400% 0;
    }
  }

  // Demo layer
  .wrap:has(:active) {
    scale: 0.99;
  }

  .wrap {
    transition: scale 0.25s;
  }

  .wrap {
    transform: scale(var(--scale, 1));
  }

  .wrap #load-editor-button {
    --border: rgba(0, 0, 0, 0.15);
    --base: oklch(70% 0.155 360);
    --base: hsl(10 90% 70%);

    border-radius: 6px;
    border: calc(var(--border-width, 2) * 1px) solid #0000;
    color: rgba(0, 0, 0, 0.7);
    font: inherit;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      border: calc(var(--border-width, 2) * 1px) solid #0000;
      inset: -8px;
      border-radius: 14px;
      opacity: 0;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      filter: blur(calc(var(--blur, 10) * 1px))
        brightness(var(--brightness, 1.25));
      inset: 60% 0 0 0;
      border-radius: 50% 50% 6px 6px / 50% 50% 6px 6px;
      transform-origin: 50% 25%;
      opacity: 1;
      scale: 0.6 0.4;
      transition: scale 0.24s;
    }

    kbd {
      font: inherit;
      font-size: 0.75em;
      opacity: var(--active, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0.2em;
      border-radius: 4px;
      letter-spacing: 2px;
      background-color: #f5f5f5;
    }
  }

  #load-editor-button:is(:hover, :focus-visible)::before {
    scale: 1.1 1.25;
  }

  #load-editor-button:focus-visible::after {
    opacity: 0.6;
  }
`;

export const AnnouncementBox = styled.div`
  code {
    background-color: rgba(237, 233, 254, 0.5);
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
    color: #453F9C;
    font-family: ui-monospace, monospace;
  }
`;

export const GlowBackgroundContainer = styled.div`
  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.8; }
  }
  @keyframes grain {
    0%, 100% { transform: translate(0, 0) }
    10% { transform: translate(-5%, -5%) }
    20% { transform: translate(-10%, 5%) }
    30% { transform: translate(5%, -10%) }
    40% { transform: translate(-5%, 15%) }
    50% { transform: translate(-10%, 5%) }
    60% { transform: translate(15%, 0%) }
    70% { transform: translate(0%, 10%) }
    80% { transform: translate(-15%, 0%) }
    90% { transform: translate(10%, 5%) }
  }

  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  perspective: 1000px;

  .primary-glow {
    position: absolute;
    width: 500px;
    height: 500px;
    pointer-events: none;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle, rgba(99, 102, 241, 0.2) 0%, rgba(99, 102, 241, 0.1) 40%, transparent 70%);
    border-radius: 50%;
    mix-blend-mode: multiply;
    filter: blur(30px) contrast(150%);
    animation: pulse 4s ease-in-out infinite;
  }

  .secondary-glow {
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(223, 222, 248, 0.3) 0%, rgba(223, 222, 248, 0.15) 40%, transparent 70%);
    filter: blur(20px) contrast(120%);
    transform: translate(calc(-50% + ${Math.sin(Date.now() * 0.001) * 50}px), calc(-50% + ${Math.cos(Date.now() * 0.001) * 50}px));
  }
`;

export const Grain = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.2;
  mix-blend-mode: overlay;
  background: url("data:image/svg+xml,${encodeURIComponent(`
    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <filter id="grain">
        <feTurbulence type="fractalNoise" baseFrequency="0.85" numOctaves="4" stitchTiles="stitch"/>
      </filter>
      <rect width="100%" height="100%" filter="url(#grain)" opacity="0.25"/>
    </svg>
  `)}");
  animation: grain 8s steps(10) infinite;
`;
