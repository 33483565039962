/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { useHasuraLazyQuery } from '../../../app/hooks/useHasuraQuery';

type TimelineQueryResponse = {
  paradime_user_facing_analytics_dbt_run_results: {
    run_result_unique_id: string,
    run_result_thread_id: string,
    run_result_timing_compile_started_at: string,
    run_result_timing_compile_completed_at: string,
    run_result_timing_execute_started_at: string,
    run_result_timing_execute_completed_at: string,
  }[],
}

type ThreadItemType = {
  id: number,
  content: string,
}

type TimelineDataItemType = {
  id: number,
  group: string,
  start: number,
  end: number,
  content: string,
  elapsed_compile: number,
  elapsed_execute: number,
  title: string,
};

type TimelineDataType = {
  items: TimelineDataItemType[],
  start: number,
  end: number,
  threads: ThreadItemType[],
}

export interface TimelineView {
  data?: TimelineDataType,
}

const timelineViewQuery = gql`
  query TimelineViewData ($runId: Int!, $loadedStartDate: Timestamp, $loadedEndDate: Timestamp) {
    paradime_user_facing_analytics_dbt_run_results(
      where: {
        bolt_schedule_run_id: { _eq: $runId },
        paradime_loaded_at_dttm: {
          _gte: $loadedStartDate,
          _lte: $loadedEndDate
        }
      }
    ) {
      run_result_thread_id
      run_result_unique_id
      run_result_timing_compile_completed_at
      run_result_timing_compile_started_at
      run_result_timing_completed_at
      run_result_timing_execute_completed_at
      run_result_timing_execute_started_at
      run_result_timing_started_at
    }
  }
`;

const useGetTimelineData = (runId: number, endDttm?: string | null) => {
  const [timelineView, setTimelineView] = useState<TimelineView>();
  const [deadlinePassed, setDeadlinePassed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const endDate = DateTime.fromISO(endDttm || '', { zone: 'utc' });
  const loadedStartDate = endDttm ? endDate.minus({ days: 1 }).toFormat('yyyy-LL-dd') : undefined;
  const loadedEndDate = endDttm ? endDate.plus({ days: 1 }).toFormat('yyyy-LL-dd') : undefined;

  const [getTimelineData] = useHasuraLazyQuery<TimelineQueryResponse>({
    query: timelineViewQuery,
    variables: { runId, loadedStartDate, loadedEndDate },
    onCompleted: ({ data }) => {
      const timelineViewDataResponse = data.paradime_user_facing_analytics_dbt_run_results;
      if (timelineViewDataResponse?.length > 0) {
        const startItem = timelineViewDataResponse.reduce((prev, curr) => {
          const prevDate = new Date(prev.run_result_timing_compile_started_at).getTime();
          const currDate = new Date(curr.run_result_timing_compile_started_at).getTime();
          return (prevDate < currDate ? prev : curr);
        });

        const endItem = timelineViewDataResponse.reduce((prev, curr) => {
          const prevDate = new Date(prev.run_result_timing_execute_completed_at).getTime();
          const currDate = new Date(curr.run_result_timing_execute_completed_at).getTime();
          return (prevDate > currDate ? prev : curr);
        });

        const threads = [...new Set(
          timelineViewDataResponse.map((item) => item.run_result_thread_id),
        )]
          .sort((a, b) => {
            const aId = parseInt(a.split('-')[1], 10);
            const bId = parseInt(b.split('-')[1], 10);

            if (aId > bId) return 1;
            if (aId < bId) return -1;
            return 0;
          })
          .map((thread, id) => ({ id, content: thread } as ThreadItemType));

        const timelineDataItems = timelineViewDataResponse.map((item, id) => {
          const compileComplete = new Date(item.run_result_timing_compile_completed_at).getTime();
          const executeComplete = new Date(item.run_result_timing_execute_completed_at).getTime();

          const elapsedCompile = (
            compileComplete
              - new Date(item.run_result_timing_compile_started_at).getTime()
          );

          const elapsedExecute = (
            executeComplete
              - new Date(item.run_result_timing_execute_started_at).getTime()
          );

          const title = `${item.run_result_unique_id}<br/> Duration Compile: ${elapsedCompile}ms <br/> Duration Execute: ${elapsedExecute}ms`;

          return {
            id,
            group: threads.find(({ content }) => content === item.run_result_thread_id)?.id?.toString() || '-1',
            start: compileComplete,
            end: executeComplete,
            content: item.run_result_unique_id,
            elapsed_compile: elapsedCompile,
            elapsed_execute: elapsedExecute,
            title,
          };
        });

        setTimelineView({
          data: {
            items: timelineDataItems,
            start: new Date(startItem.run_result_timing_compile_started_at).getTime(),
            end: new Date(endItem.run_result_timing_execute_completed_at).getTime(),
            threads,
          },
        });

        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (endDttm && !timelineView) {
      setTimeout(() => {
        setIsLoading(true);
        const elapsedSeconds = -DateTime.fromISO(endDttm).diffNow('seconds').seconds;
        setDeadlinePassed(elapsedSeconds > 60);
        getTimelineData();
      }, 5 * 1000);
    }
  }, [endDttm, timelineView]);

  return {
    timelineView,
    isLoading,
    deadlinePassed,
  };
};

export { useGetTimelineData };
