import React, { FC } from 'react';
import * as S from './Editor.styles';

export interface LoadEditorButtonProps {
  onClick: () => void,
}

const LoadEditorButton: FC<LoadEditorButtonProps> = ({ onClick }) => (
  <S.PageStyles>
    <div className="wrap">
      <button id="load-editor-button" onClick={onClick}>
        <span />
        <span />
        <span />
        <span>
          Launch Editor
          {' '}
          <kbd>Enter↵</kbd>
        </span>
      </button>
    </div>
  </S.PageStyles>
);

export default LoadEditorButton;
