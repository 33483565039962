/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@blueprintjs/core';
import LoadEditorButton from './LoadEditorButton';
import { useGetAppTips } from './hooks/useGetAppTips';
import GlowBackground from './GlowBackground';
import * as S from './Editor.styles';

interface MarkdownTextProps {
  text: string;
}
interface AnnouncementBoxProps {
  children: string;
}

interface LoadingScreenProps {
  onLoadEditorClick: () => void,
  onLoadingCompleted: () => void,
  editorIsReady: boolean,
}

interface TerminalLoaderProps {
  onLoadingCompleted: () => void,
  appTip: string,
  editorIsReady: boolean,
}

const MarkdownText: React.FC<MarkdownTextProps> = ({ text: txt }) => {
  if (txt.includes('<code>') || txt.includes('<a>')) {
    // Render HTML
    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: txt.replace('**Did you know?**', '<b>Did you know?</b>'),
        }}
      />
    );
  }

  const renderMarkdown = (text: string): (JSX.Element | null)[] => (
    text.split(/(\*\*.*?\*\*)|(`.*?`)/).map((segment, index) => {
      if (!segment) return null;
      if (segment.startsWith('**') && segment.endsWith('**')) {
        return <strong key={index}>{segment.slice(2, -2)}</strong>;
      }
      if (segment.startsWith('`') && segment.endsWith('`')) {
        return (
          <code
            key={index}
            style={{
              backgroundColor: 'rgba(237, 233, 254, 0.5)',
              padding: '0.125rem 0.25rem',
              borderRadius: '0.25rem',
              color: '#453F9C',
              fontFamily: 'ui-monospace, monospace',
            }}
          >
            {segment.slice(1, -1)}
          </code>
        );
      }
      return <span key={index}>{segment}</span>;
    })
  );
  return <div>{renderMarkdown(txt)}</div>;
};

const AnnouncementBox: React.FC<AnnouncementBoxProps> = ({ children }) => (
  <S.AnnouncementBox style={{
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(8px)',
    borderRadius: '0.5rem',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    border: '1px solid #D0CDF5',
    overflow: 'hidden',
  }}
  >
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '4px',
      height: '100%',
      backgroundColor: '#635AE0',
    }}
    />
    <div style={{
      padding: '1rem',
      paddingLeft: '1.5rem',
    }}
    >
      <div style={{
        position: 'relative',
        zIndex: 10,
        display: 'flex',
        alignItems: 'flex-start',
        gap: '0.75rem',
      }}
      >
        <div>
          <Icon
            icon="lightbulb"
            color="var(--violet50)"
            size={20}
            style={{
              width: '1.25rem',
              height: '1.25rem',
              marginTop: '0.125rem',
              color: '#635AE0',
            }}
          />
        </div>
        <div style={{ color: '#453F9C' }}>
          <MarkdownText text={children} />
        </div>
      </div>
    </div>
  </S.AnnouncementBox>
);

const TerminalLoader: React.FC<TerminalLoaderProps> = ({
  onLoadingCompleted,
  appTip,
  editorIsReady,
}) => {
  const [currentLineIndex, setCurrentLineIndex] = useState<number>(0);
  const [currentText, setCurrentText] = useState<string>('');
  const [completedLines, setCompletedLines] = useState<number[]>([]);
  const [cursorVisible, setCursorVisible] = useState<boolean>(true);
  const [isTypingComplete, setIsTypingComplete] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const loadingStates: string[] = [
    'Getting your workspace ready...',
    'Setting up your connections...',
    'Launching editor...',
  ];

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [currentText, completedLines]);

  useEffect(() => {
    if (currentLineIndex >= loadingStates.length) {
      setIsTypingComplete(true);
      onLoadingCompleted();
      return;
    }

    const currentFullText = loadingStates[currentLineIndex];
    const textLength = currentText.length;

    if (textLength === currentFullText.length) {
      const isLastLine = currentLineIndex === loadingStates.length - 1;

      let delay = 3000;
      if (isLastLine) delay = 800;
      if (editorIsReady) delay = 200;

      const timeout = setTimeout(() => {
        setCompletedLines((prev) => [...prev, currentLineIndex]);
        setCurrentLineIndex((prev) => prev + 1);
        setCurrentText('');
      }, delay);
      return () => clearTimeout(timeout); // eslint-disable-line
    }

    const typingDelay = editorIsReady ? 0 : 200;
    const typingTimeout = setTimeout(() => {
      setCurrentText(currentFullText.slice(0, textLength + 1));
    }, typingDelay);

    return () => clearTimeout(typingTimeout); // eslint-disable-line
  }, [currentLineIndex, currentText]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setCursorVisible((prev) => !prev);
    }, 530);
    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <div style={{
      width: '100%',
      maxWidth: '32rem',
      margin: '0 1rem',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    }}
    >
      <div style={{
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(8px)',
        borderRadius: '0.5rem',
        padding: '1.5rem',
        fontFamily: 'ui-monospace, monospace',
        fontSize: '0.875rem',
        border: '1px solid #D0CDF5',
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        height: '12rem',
      }}
      >
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '2rem',
          background: 'linear-gradient(to bottom, white, transparent)',
          zIndex: 10,
        }}
        />
        <div style={{ position: 'relative', zIndex: 10 }}>
          <div ref={contentRef} style={{ position: 'relative', height: '100%', overflow: 'auto' }}>
            <div style={{
              display: 'flex', flexDirection: 'column', gap: '0.5rem', color: '#4F48B3',
            }}
            >
              {completedLines.map((index) => (
                <div key={`completed-${index}`} style={{ display: 'flex', alignItems: 'center', minHeight: '1.5rem' }}>
                  <div style={{
                    width: '1.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center',
                  }}
                  >
                    <Icon icon="tick" color="var(--green50)" size={15} style={{ width: '1rem', height: '1rem', color: '#10B981' }} />
                  </div>
                  <span style={{ color: '#635AE0' }}>
                    {loadingStates[index]}
                  </span>
                </div>
              ))}
              {!isTypingComplete && (
                <div style={{ display: 'flex', alignItems: 'center', minHeight: '1.5rem' }}>
                  <span style={{
                    width: '1.25rem', display: 'flex', justifyContent: 'center', color: '#635AE0',
                  }}
                  >
                    &gt;
                  </span>
                  <span style={{ color: '#635AE0' }}>
                    {currentText}
                    <span
                      style={{
                        display: 'inline-block',
                        width: '0.5rem',
                        height: '1rem',
                        marginLeft: '0.25rem',
                        backgroundColor: '#635AE0',
                        opacity: cursorVisible ? 0.9 : 0,
                      }}
                    />
                  </span>
                </div>
              )}
              {isTypingComplete && (
                <div style={{ display: 'flex', alignItems: 'center', minHeight: '1.5rem' }}>
                  <span style={{
                    width: '1.25rem', display: 'flex', justifyContent: 'center', color: '#635AE0',
                  }}
                  >
                    &gt;
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '0.5rem',
                      height: '1rem',
                      backgroundColor: '#635AE0',
                      opacity: cursorVisible ? 0.9 : 0,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '2rem',
          background: 'linear-gradient(to top, white, transparent)',
          zIndex: 10,
        }}
        />
      </div>
      <AnnouncementBox>
        {`**Did you know?** ${appTip}`}
      </AnnouncementBox>
    </div>
  );
};

const PreviewApp: React.FC<LoadingScreenProps> = ({
  onLoadEditorClick,
  onLoadingCompleted,
  editorIsReady,
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { getRandomTip } = useGetAppTips();

  const handleClick = () => {
    setShowLoader(true);
    onLoadEditorClick();
  };

  const keyboardControls = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClick();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyboardControls);

    return () => window.removeEventListener('keydown', keyboardControls);
  }, []);

  return (
    <div style={{
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      position: 'relative',
      overflow: 'hidden',
    }}
    >
      <GlowBackground />
      <div style={{ position: 'relative', zIndex: 10 }}>
        {showLoader ? (
          <TerminalLoader
            onLoadingCompleted={onLoadingCompleted}
            appTip={getRandomTip()}
            editorIsReady={editorIsReady}
          />
        ) : (
          <LoadEditorButton onClick={handleClick} />
        )}
      </div>
    </div>
  );
};
export default PreviewApp;
